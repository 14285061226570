import { Box, Grid, Typography } from "@mui/material"
import MuiField from "./MuiField"
import { StyledMuiFieldLabel } from "./muiFields/MuiFIeldLabel"
import { Separator } from "./Separator"
import useWindowSize from "../windowSize"

interface IPairedNumberProps {
  bigLabel: string,
  elements: JSX.Element[]
}

export function PairedInput(props : IPairedNumberProps) : JSX.Element | null {
  const size = useWindowSize()

  if(props.elements.length === 0) return null
  if(allDisabled(props.elements) === true) return null
  if(size.width < 1500 && size.width > 1100) return <PairedInputSmall {...props} />
  
  return (<>
    <Separator/>
    <Grid container justifyContent="center">
      <Grid item sx={{marginTop:"15px"}} xs={12} md={5}>
        <StyledMuiFieldLabel paired={false} label={props.bigLabel} />
      </Grid>

      <Grid item xs={12} md={7}>
        {props.elements.map((value, index, array) => <Box key={index} sx={{marginBlock:"15px"}}>{value}</Box>)}
      </Grid>
    </Grid>
  </>)
}

export function PairedInputSmall(props : IPairedNumberProps) : JSX.Element | null {
  
  return (<>
    <Separator/>

        <StyledMuiFieldLabel paired={false} label={props.bigLabel} />
      

      
        {props.elements.map((value, index, array) => <Box key={index} sx={{marginBlock:"0px"}}>{value}</Box> )}

  </>)
}

function allDisabled(elements : JSX.Element[]) : Boolean {
  for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    if(element.props.disabled !== true) return false
  }

  return true
}