import { Field, FieldInputProps, FieldProps, getIn } from "formik";
import { IProps } from "../MuiField";
import { getOnChange, getOnChangeNumberInput } from "../../models/Form";
import { Box, Button, Grid, TextField } from "@mui/material";
import { StyledMuiFieldLabel } from "./MuiFIeldLabel";
import React from "react";
import { ISolution } from "../../models/Solution";
import errorFields from "../ErrorFields";

interface IRawStyledNumberMuiField {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    name: string,
    integer: boolean,
    disabled?: boolean
    defaultValue: number
    step?: number
    min?: number
    max?: number
    thisField?: FieldInputProps<any>
}

export function StyledNumberMuiField(props : IProps) : JSX.Element {
  const disabled = props.disabled ?? false;

  let xsmd;
  if(props.xsmd === undefined || props.xsmd.length != 2) {
    xsmd = [5,7]
  } else {
    xsmd = props.xsmd
  }

  // const { t } = useTranslation();
  const form = props.form;
  const field = props.field;


  return (
      <Grid container alignItems="center">
          <Grid item xs={12} md={xsmd[0]}>
              <StyledMuiFieldLabel label={props.label} paired={props.paired === true}/>

          </Grid>
          <Grid item xs={12} md={xsmd[1]} display="flex" flexDirection={props.alignRight === true ? "row-reverse" : "row"}>
              <LabellessStyledNumberMuiField thisField={props.field} 
              name={field.name} step={props.step} min={props.min} 
              max={props.max} onChange={getOnChange(props)} 
              defaultValue={field.value} disabled={disabled} 
              integer={props.integer===true}/>
          </Grid>
      </Grid>
  )   
}

function getValue(object : any, path : string) {
    const properties = path.split('.');
    let value = object;
    for (const property of properties) {
      if (value && value.hasOwnProperty(property)) {
        value = value[property];
      } else {
        return undefined;
      }
    }
    return value;
  }

export function LabellessStyledNumberMuiField(props : IRawStyledNumberMuiField) : JSX.Element {
    return <Box display="flex" justifyContent="start" alignItems="center">
    <MinusButton name={props.name} step={props.step} />
    <RawNumberMuiField {...props} min={props.min} max={props.max}/>
    <PlusButton name={props.name} step={props.step} />
    </Box>
}

const buttonsx = {
    '&.MuiButton-root': {
        border: "1px solid #e0ddf2",
        borderRadius: "15px",
        width: "33px",
        height: "33px",
        background: "white",
        color: "#1a0549",
        padding: "0",
        maxHeight: "50px",
        maxWidth: "50px",
        minWidth: "10px",
        
        "&:hover": {
        cursor: "pointer",
        background: " #1a0549",
        borderColor: " #1a0549",
        color: "white",
        },
    }
}

function PlusButton(props: {name : string, step? : number}) : JSX.Element{
    return (
    <Field component={(fieldProps: FieldProps<number, ISolution>) => <>
        <Button 
            sx = {buttonsx}
            onClick={() => {
                const newValue = Number(getValue(fieldProps.form.values, props.name))+(props.step ? props.step : 1)
                fieldProps.form.setFieldValue(props.name, newValue)
                //Really non-react solution
                let doc = document.getElementById(props.name) as HTMLInputElement
                doc.value = `${newValue}`
                const valid = (doc as HTMLInputElement).validity.valid
                
                if(!valid) {if(errorFields[props.name] !== true) { errorFields[props.name] = true }}
                else if(valid) {if(errorFields[props.name] === true) { errorFields[props.name] = false }}              
            }}> 
            + 
            </Button>
    </>}/>
    )
}

function MinusButton(props: {name : string, step? : number}) : JSX.Element{
    return (
    <Field component={(fieldProps: FieldProps<number, ISolution>) => <>
        <Button 
            sx = {buttonsx}
            onClick={() => {
                const newValue : number = Number(getValue(fieldProps.form.values, props.name))-(props.step ? props.step : 1)
                fieldProps.form.setFieldValue(props.name, newValue)
                //Again non-react solution
                let doc = document.getElementById(props.name) as HTMLInputElement
                doc.value = `${newValue}`
                const valid = (doc as HTMLInputElement).validity.valid
                
                if(!valid) {if(errorFields[props.name] !== true) { errorFields[props.name] = true; console.log("davam true na " + props.name) }}
                else if(valid) {if(errorFields[props.name] === true) { errorFields[props.name] = false; console.log("davam false na " + props.name) }}
            }}> 
            -
            </Button>
    </>}/>
    )
}

export function RawNumberMuiField(props : IRawStyledNumberMuiField) : JSX.Element {       
    //let error = (props.min==undefined && props.max===undefined) ? false : (props.thisField === undefined ? false : ((props.min===undefined ? false : (props.thisField.value <= props.min)) || (props.max === undefined ? false : (props.thisField.value >= props.max))))
    //const pattern = props.integer===true ? {pattern:"[0-9]+"} : {pattern:"-?[0-9]+([.][0-9]?)?"}
    const step = props.integer===true ? 1 : 0.01

    return <TextField
        id={props.name}
        name={props.name}
        type="number"
        disabled={props.disabled}
        value={props.defaultValue}
        onChange={props.onChange}
        //error={error}
        InputProps={{inputProps:{min: props.min, max: props.max, step: step}}}
        sx={{
            textAlign: "center",
            marginTop:"0px",
            marginBottom: "0px",
            marginRight:"7px",
            marginLeft:"7px",
            width:"90px",
            backgroundColor: "white",
            border: "1px solid #dfe0f2",
            borderRadius: "14px",
            'input':{
                textAlign: "center",
                "&:invalid": {
                    borderRadius:"14px",
                    border: "red solid 1px"
                }
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: "0px solid black",
            },
            '& .MuiOutlinedInput-root': {
                color: "#250048",
                padding:"0px",
                borderRadius: "14px",
                '&.Mui-focused fieldset': {
                    borderColor: '#1a0549',
                },
            },
            '& .MuiOutlinedInput-input': {
                padding:"0px",
                paddingInline:"10px",
                paddingBlock:"5px"
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& .Mui-error': {
                border: "1px solid red",
            }
        }}
    />
}