import { Box, Grid, TextField, Typography } from "@mui/material";
import { SolutionField } from "./SolutionField";
import { Field, FieldProps } from "formik";
import { ISolution } from "../models/Solution";
import { LabellessStyledNumberMuiField } from "./muiFields/StyledNumberMuiField";
import { StyledCheckBox } from "./Checkbox";
import useWindowSize from "../windowSize";

interface ICornerRadiusFieldProps{
  allCornersSame: boolean,
  onCheck: (_ : any, val : boolean) => void
}

export function CornerRadiusField(props : ICornerRadiusFieldProps) : JSX.Element {
  const size = useWindowSize()
  const direction = (size.width < 1600 && size.width > 1100) ? "column" : "row"
  const sx_ = (size.width < 1600 && size.width > 1100) ? {marginBlockEnd: "15px"} : {}
  return (
    <Grid container direction={direction} >
      <Grid item xs={12} md={5} sx={{...sx_}}>
        <Typography paddingRight="20px" color="#250048" fontSize="100%" maxWidth="200px">Corner radius (mm)</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <CornerRadiusInput allCornersSame={props.allCornersSame} onCheck={props.onCheck}/>
        <StyledCheckBox name="Independent corners" checked={!props.allCornersSame} onChange={props.onCheck}/>
      </Grid>
    </Grid>
  )
}

function CornerRadiusInput(props : ICornerRadiusFieldProps) : JSX.Element {
  if(props.allCornersSame === true) return (
    <Field component={(fieldProps: FieldProps<number, ISolution>) =><>
      <LabellessStyledNumberMuiField
        integer={false}
        name='customization.corners.tl'
        disabled={false}
        defaultValue={fieldProps.form.values.customization?.corners.tl ? fieldProps.form.values.customization?.corners.tl : 0}
        onChange={(event) => {
          fieldProps.form.setFieldValue('customization.corners.tl', event.target.value);
          fieldProps.form.setFieldValue('customization.corners.tr', event.target.value);
          fieldProps.form.setFieldValue('customization.corners.bl', event.target.value);
          fieldProps.form.setFieldValue('customization.corners.br', event.target.value);
        } } 
      />
    </>}/>
  )
  else return (
    <CornerIndependent/>
  )
}

function CornerIndependent() : JSX.Element {
  const size = useWindowSize()
  return (<Box>
    <Grid container alignItems="center">
      <Grid>
        <Box
          sx={{
            marginRight:"15px",
            height: "15px",
            width: "15px",
            borderRadius: "5px 0 0 0",
            borderTop:"2px solid #250048",
            borderLeft: "2px solid #250048"
          }}
        />
      </Grid>
      <Grid>
        <SolutionField name="customization.corners.tl" />
      </Grid>
      <Grid>
      <SolutionField name="customization.corners.tr" />
      </Grid>
      <Grid>
        <Box
          sx={{
            height: "15px",
            width: "15px",
            borderRadius: "0 5px 0 0",
            borderTop:"2px solid #250048",
            borderRight: "2px solid #250048"
          }}
        />
      </Grid>
    </Grid>

    <Grid container alignItems="center" paddingTop="15px">
      <Grid>
        <Box
          sx={{
            marginRight:"15px",
            height: "15px",
            width: "15px",
            borderRadius: "0 0 0 5px",
            borderBottom:"2px solid #250048",
            borderLeft: "2px solid #250048"
          }}
        />
      </Grid>
      <Grid>
        <SolutionField name="customization.corners.bl" />
      </Grid>
      <Grid>
        <SolutionField name="customization.corners.br" />
      </Grid>
      <Grid>
        <Box
          sx={{
            height: "15px",
            width: "15px",
            borderRadius: "0 0 5px 0",
            borderBottom:"2px solid #250048",
            borderRight: "2px solid #250048"
          }}
        />
      </Grid>
    </Grid>
  </Box>)
}